import { Vue, prop, Options } from 'vue-class-component'
import apiService from '@/services/api-service'
import { Config } from '@/config'
import utilityService from '@/services/utility-service'
import PersonAdvanceSearch from '../PersonAdvanceSearch/PersonAdvanceSearch.vue'
import CompanyAdvanceSearch from '../CompanyAdvanceSearch/CompanyAdvanceSearch.vue'
import { getSingleSearchResults } from '@/models/search'

// Define props in a class
class Props {
  page = prop({
    // Same as Vue core's prop option
    type: String,
    required: false,
    default: 'home'
  });
}

@Options({
  components: { PersonAdvanceSearch, CompanyAdvanceSearch }
})
export default class Search extends Vue.with(Props) {
  Config = Config
  public searchResult: any[] = [];
  public searchingCompleted = false;
  isFormValid = true;
  formError: any = {};
  paginationSubscription: any;
  searchTerm = '';
  searchType: any = { person: true, company: true };
  activeTab = Config.CONSTANTS.PERSON;
  openAdvanceSearch = false;

  mounted () {
    const query = this.$route.query
    if (query.input) {
      this.searchTerm = this.$route.query.input as string
      utilityService.updatePageTitle(this.searchTerm)
    }

    if (query.company === 'false') {
      this.searchType.company = false
    }

    if (query.person === 'false') {
      this.searchType.person = false
    }
  }

  created () {
    if (!apiService.isInitialSearchDone) {
      apiService.onSearchOptionChanged.next(this.searchType)
    }
  }

  public onBasicSearch () {
    this.hideToast()
    if (this.validateSearchTerm()) {
      apiService.resetResult()
      apiService.onQuickFilterChangedSubject.next({
        reset: true,
        form: '',
        doSearch: false
      })
      apiService
        .getSingleSearchResult(this.getSearchType(), this.searchTerm)
        .then((data: any) => {
          const [params, apiService, responseType] = getSingleSearchResults(data, this.searchType)
          utilityService.updatePageTitle(this.searchTerm)
          this.hideToast()
          this.$router
            .push({
              name: 'Result',
              query: params
            })
            .then(() => {
              apiService.emitNewResult(responseType, true)
            })
        })
    }
  }

  validateSearchTerm () {
    const valueLength = this.searchTerm?.trim()?.length
    if (valueLength < 1) {
      this.onFieldValidationFailed(
        'search',
        this.$t('default.form_error.one_char_count')
      )
      return false
    } else if (valueLength > 200) {
      this.onFieldValidationFailed(
        'search',
        this.$t('default.form_error.max_char_count')
      )
      return false
    }
    return true
  }

  onFieldValidationFailed (field: string, message: string) {
    this.formError[field] = true
    this.isFormValid = false
    utilityService.showToast(message, 'error', 'form_error')
  }

  canShowFormError (control: string) {
    return this.formError[control]
  }

  getSearchType (): string {
    if (this.searchType.person && this.searchType.company) {
      return 'all'
    } else if (this.searchType.person) {
      return Config.CONSTANTS.PERSON
    } else if (this.searchType.company) {
      return 'organisation'
    } else {
      return 'all'
    }
  }

  public hideNoResultMsg () {
    this.hideToast()
    this.searchingCompleted = false
  }

  public isCompactForm () {
    return this.page === 'result'
  }

  public canShowNoResult () {
    return (
      this.searchingCompleted &&
      this.searchResult.length === 0 &&
      this.page === 'home'
    )
  }

  hideToast () {
    utilityService.hideToast()
  }

  resetName () {
    this.searchTerm = ''
    this.formError.search = false
  }

  onSearchToggle (type: string) {
    this.searchType[type] = !this.searchType[type]
    if (type === Config.CONSTANTS.PERSON && !this.searchType.person) {
      this.searchType.company = true
      this.activeTab = Config.CONSTANTS.COMPANY
    } else if (type === Config.CONSTANTS.COMPANY && !this.searchType.company) {
      this.searchType.person = true
      this.activeTab = Config.CONSTANTS.PERSON
    }
    apiService.onSearchOptionChanged.next(this.searchType)
    let params: any = Object.assign({}, this.$route.query)
    if (!params) {
      params = {
        company: this.searchType.company,
        person: this.searchType.person
      }
    } else {
      params.company = this.searchType.company
      params.person = this.searchType.person
    }
    params.search_type = this.activeTab
    if (this.isCompactForm()) {
      this.gotoResultPage(params)
    }
  }

  gotoResultPage (params: any) {
    this.$router.push({
      name: 'Result',
      query: params
    })
  }

  getPlaceholder () {
    return (
      this.$t('default.search_form.name') +
      ' / ' +
      this.$t('default.search_form.address') +
      ' / ' +
      this.$t('default.search_form.ssn') +
      ' / ' +
      this.$t('default.search_form.org_num')
    )
  }

  toggleAdvanceSearchPopup () {
    this.openAdvanceSearch = !this.openAdvanceSearch
  }
}
