<template>
  <router-view />
</template>

<style>
.el-notification p {
  font-family: "FontAwesome";
  font-size: 16px;
  color: #d45436;
}
.el-notification h2 {
  font-family: "FontAwesome";
  font-size: 20px;
  color: #d45436;
}
</style>
<script>
import { Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import utilityService from '@/services/utility-service'
import i18n from '@/i18n'
export default class App extends Vue {
  mounted () {
    apiService.checkAuthentication()
    utilityService.updatePageTitle('')
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  }

  updateOnlineStatus () {
    utilityService.hideToast()
    if (!navigator.onLine) {
      utilityService.showToast(i18n.global.t('default.alert.network_unavailable').toString(), 'error', '', 0, false)
    }
  }
}
</script>
