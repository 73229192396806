export interface IResult {
  ssn?: string;
  first_name?: string;
  last_name?: string;
  street: string;
  zipcode: string;
  city: string;
  org_id?: string;
  display_org_id?: string;
  company_name?: string;
  company_code?: string;
}

export enum ContainerType {
  LIST = 'list',
  TABLE = 'table',
  COMPOSITE = 'composite',
  CHART = 'chart',
  REPORT = 'report'
}

export enum ISearchType {
  PERSON = 'person',
  COMPANY = 'company'
}

export interface IPaginationParam {
  page: number;
  page_size: number;
  form: string;
}

export interface IRow {
  title: string;
  key: string;
}

export interface IColumn {
  title: string;
  key: string;
}

export interface IParamReplace {
  param: string;
  key: string;
}

export interface IChartDataset {
  label: string;
  key: string;
  bg_color?: string | string[];
  border_color?: string | string[];
}

export interface IReportUrl {
  url: string;
  url_replace: IParamReplace[];
}

export interface IContainer {
  parentIndex?: number | null;
  visible?: boolean;
  isComposite?: boolean;
  title: string;
  sub_title?: string;
  type: ContainerType;
  scope: string[];
  rows?: IRow[];
  columns?: IColumn[];
  source?: string;
  containers?: IContainer[];
  chart_type?: string;
  chart_labels?: string;
  chart?: IChartDataset[];
  width?: string;
  report_url?: IReportUrl;
  download_url?: IReportUrl;
  download?: string;
  edit_url?: string;
}

export interface ICollections {
  containers: IContainer[];
  name: string;
}

export interface IResultGroup {
  person: {
    result: IResult[];
    totalResults: number;
    currentPageNumber: number;
    perPageCount: number;
  };
  company: {
    result: IResult[];
    totalResults: number;
    currentPageNumber: number;
    perPageCount: number;
  }
}
