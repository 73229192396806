import { createStore, createLogger } from 'vuex'
// eslint-disable-next-line import/no-cycle
import { store as preferences, PreferencesStore, State as PreferancesState } from '@/store/modules/preferences'
import { store as general, GeneralStore, State as GeneralState } from '@/store/modules/general'

export type RootState = {
  preferences: PreferancesState;
  general:GeneralState;
};

export type Store = PreferencesStore<Pick<RootState, 'preferences'>>
& GeneralStore<Pick<RootState, 'general'>>

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

export const store = createStore({
  plugins,
  modules: {
    preferences,
    general
  }
})

export function useStore (): Store {
  return store as Store
}
