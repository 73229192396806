
import { Config } from '@/config'
import { Options, Vue } from 'vue-class-component'
import TopHeader from '../TopHeader/TopHeader.vue'

@Options({
  components: { TopHeader }
})
export default class PageNotFound extends Vue {
  phoneDisplayText: string = Config.CONTACT_NUMBER_TO_DISPLAY;
  emailDisplayText: string = Config.CONTACT_EMAIL_TO_DISPLAY;
  phoneToBind: string = Config.CONTACT_NUMBER_TO_BIND;
  emailToBind: string = Config.CONTACT_EMAIL_TO_BIND;
  statusCode:any = 404
  mounted () {
    this.statusCode = this.$route.name
  }
}
