import { prop, Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import jsonPathService from '@/services/json-path-service'
import { Config } from '@/config'
import { IParamReplace } from '@/models/common'
import { ElMessage } from 'element-plus'

// Define props in a class
class Props {
  container = prop({
    type: Object,
    required: true,
    default: {}
  });
}

export default class ReportContainer extends Vue.with(Props) {
  dataToDisplay: any = [];
  canShowTable = false;
  minUrlLength = 4;
  canShowDownload: boolean = false;
  showMoreOption: boolean = true;
  maxRowCount = Config.TABLE_MAX_ROW_LENGTH;
  canShowMoreOption: boolean = false;
  mounted () {
    this.init()
  }

  init () {
    if (this.container.report_url.url) {
      this.canShowTable = true
      const url = this.replaceParamsInUrl(this.container.report_url.url, this.container.report_url.url_replace, apiService.containerData)
      apiService.get(url).then((data: any) => {
        data = jsonPathService.getValueByField(data, this.container.source)
        this.dataToDisplay = this.createDownloadUrl(data)
        if (
          !Array.isArray(this.dataToDisplay) &&
          typeof this.dataToDisplay === 'object'
        ) {
          const result = this.dataToDisplay
          this.dataToDisplay = []
          if (result) {
            this.dataToDisplay.push(result)
          }
        }
        this.canShowDownload = this.hasDownloadUrlInAnyRows(this.dataToDisplay)
        this.canShowMoreOption = this.dataToDisplay.length > this.maxRowCount
      })
    } else {
      this.dataToDisplay = []
    }
  }

  createDownloadUrl (data: any): any {
    if (this.container.download_url.url && this.container.download === 'enable') {
      data.map((item: any) => {
        item.active_download_url = this.replaceParamsInUrl(this.container.download_url.url, this.container.download_url.url_replace, item)
      })
    }
    return data
  }

  replaceParamsInUrl (url: string, replaceParams: IParamReplace[], sourceData: any) {
    if (replaceParams) {
      replaceParams.forEach(element => {
        url = url.replaceAll(element.param, jsonPathService.getValueByField(sourceData, element.key))
      })
    }
    return url
  }

  getFieldByKey (source: any, accessKey: string, sanitize = true) {
    const result = jsonPathService.getValueByField(source, accessKey)
    if (sanitize) {
      return this.$sanitize(result)
    } else {
      return result
    }
  }

  getCellValue (source: any, accessKey: string) {
    const value = this.getFieldByKey(source, accessKey, false)
    if (Array.isArray(value)) {
      const result = value.toString()
      return result.length > 0 ? result : 'N/A'
    } else if (typeof value === 'number') {
      return value
    } else {
      return value || 'N/A'
    }
  }

  getColumnCount () {
    return this.container.columns.length + 1
  }

  hasDownloadUrlInAnyRows (rows: any[]) {
    if (Array.isArray(rows)) {
      const result = rows.filter(
        row => row.active_download_url?.length > this.minUrlLength
      )
      return result?.length > 0
    } else {
      return false
    }
  }

  downloadFile (file: any) {
    if (!file.isDownloading) {
      file.isDownloading = true
      apiService.getReportPath(file.active_download_url).then((data:any) => {
        apiService.getReportFileAsBlob(data.file).then((blobData:any) => {
          try {
            const blob = new Blob([blobData], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            const anchorElement = document.createElement('a')
            anchorElement.href = url
            anchorElement.download = file.ref_no
            anchorElement.click()
            file.isDownloading = false
          } catch (e) {
            this.handleDownloadFailure(file)
          }
        })
      }).catch(() => {
        this.handleDownloadFailure(file)
      })
    }
  }

  handleDownloadFailure (file: any) {
    file.isDownloading = false
    ElMessage({
      message: this.$t('default.alert.server_error_details'),
      type: 'error',
      offset: 95
    })
  }

  toggleShowMore () {
    this.showMoreOption = !this.showMoreOption
  }
}
