import { PreferencesActionTypes } from '@/store/modules/preferences/action-types'
import { useStore } from '@/store'
import { localeName } from '@/models/enum'
import { Config } from '@/config'
import { Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import utilityService from '@/services/utility-service'

export default class TopHeader extends Vue {
  appsList: any[] = []
  homePageUrl = Config.HOME_PAGE_URL
  activeSectionKey = Config.ACTIVE_SECTION_KEY

  store = useStore()
  subjectSubscription: any
  mounted () {
    this.appsList = Config.APP_LIST
    this.subjectSubscription = apiService.onSectionListFetchedSubject.subscribe(() => {
      this.appsList = Config.APP_LIST
      if (!this.getAPRName()) {
        utilityService.handleForbiddenResponse()
      }
    })
  }

  unmounted () {
    this.subjectSubscription.unsubscribe()
  }

  switchApps (event: any, item: any) {
    const switchApp = document.getElementById('switch-app')
    if (switchApp) {
      switchApp.click()
    }
    window.open(item.link)
  }

  checkAppLanguage (code: string): boolean {
    return this.store.getters.getActiveLangauge === code
  }

  changeLanguage (code: string) {
    this.$i18n.locale = code as string
    this.store.dispatch(PreferencesActionTypes.SetAppLanguage, code as localeName);
    (this as any).$root.$forceUpdate()
  }

  getAPRName () {
    const app = this.appsList.find((apps) => apps.identification_key.toUpperCase() === this.activeSectionKey)
    return app?.name
  }
}
