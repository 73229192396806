import { localeName } from '@/models/enum'
import { MutationTree } from 'vuex'

import { State } from './state'
import { PreferencesMutationTypes } from './mutation-types'

export type Mutations<S = State> = {
  [PreferencesMutationTypes.SET_LANG](state: S, payload: localeName): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [PreferencesMutationTypes.SET_LANG] (state: State, locale: localeName) {
    localStorage.setItem('APP_LANGUAGE', locale)
    state.appLang = locale
  }
}
