import { Config } from '@/config'
import apiService from '@/services/api-service'
import { useStore } from '@/store'
import { prop, Vue } from 'vue-class-component'
import { NavigationGuardNext, RouteLocationNormalized, NavigationGuard, onBeforeRouteLeave } from 'vue-router'
// Define props in a class
class Props {
  title = prop({
    type: String,
    required: true,
    default: ''
  });

  subTitle = prop({
    type: String,
    required: true,
    default: ''
  });

  searchFormName = prop({
    type: String,
    required: true,
    default: Config.CONSTANTS.PERSON
  });
}

export default class DetailsHeader extends Vue.with(Props) {
  stickHeader: boolean = false;
  store = useStore()

  created () {
    window.addEventListener('scroll', this.onWindowScroll)
    onBeforeRouteLeave(((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (apiService.isInitialSearchDone) {
        apiService.isInitialSearchDone = false
      }
      next()
    }) as NavigationGuard)
  }

  isCompanySearch () {
    return this.searchFormName === Config.CONSTANTS.COMPANY
  }

  public goBack () {
    if (apiService.isInitialSearchDone) {
      this.$router.back()
    } else {
      this.$router.push({ path: '/' })
    }
  }

  public printDetails () {
    window.print()
  }

  get hideBackgroundDetails (): boolean {
    return this.store.getters.lastKnownStatusIsError
  }

  getGoBackText () {
    if (apiService.isInitialSearchDone) {
      return this.$t('default.details_page.go_back')
    } else {
      return this.$t('default.details_page.back_to_home')
    }
  }

  onWindowScroll () {
    if (document.body.scrollTop > 97 || document.documentElement.scrollTop > 97) {
      this.stickHeader = true
    } else {
      this.stickHeader = false
    }
  }

  destroyed () {
    window.removeEventListener('scroll', this.onWindowScroll)
  }
}
