import { Vue, prop } from 'vue-class-component'
import apiService from '@/services/api-service'
import { IPaginationParam } from '@/models/common'
import utilityService from '@/services/utility-service'
import { Config } from '@/config'
import {
  getSearchResultsParams,
  getAdvancedSearchFormValues,
  processPaginationSubscription,
  processTabSubscription,
  resetErrorData
} from '@/models/search'

// Define props in a class
class Props {
  page = prop({
    // Same as Vue core's prop option
    type: String,
    required: false,
    default: 'home'
  });
}
export default class CompanyAdvanceSearch extends Vue.with(Props) {
  public searchResult: any[] = [];
  public searchingCompleted = false;

  public advancedCompanySearchForm: any = {}
  isFormValid = true;
  formError: any = {};
  paginationSubscription: any;
  apiService = apiService;
  companyTypes: any[] = [];
  fieldValidationSkipList = ['phonetic', 'company_type'];

  subscription: any;
  tabSubscription: any;
  filterSubscription: any
  isCompanySearchRequestSent: boolean = false
  defaultPaginationParams: IPaginationParam = {
    page: 1,
    page_size: this.apiService.result.company.perPageCount,
    form: Config.CONSTANTS.COMPANY
  }

  mounted () {
    this.resetFilterInput()
    this.resetErrors()

    const query = this.$route.query
    this.advancedCompanySearchForm = getAdvancedSearchFormValues(query, this.advancedCompanySearchForm, Config.CONSTANTS.COMPANY)
    this.checkAdvancedSearchFields()

    utilityService.getCompanyTypes().then((types: any) => {
      this.companyTypes = types
    })
    if (this.isCompactForm()) {
      this.paginationSubscription = processPaginationSubscription(this, Config.CONSTANTS.COMPANY)
      this.tabSubscription = processTabSubscription(this, Config.CONSTANTS.COMPANY)

      this.subscription = apiService
        .getSubscribableResultObject()
        .subscribe(data => {
          if (data.isSingleSearch) {
            this.advancedCompanySearchForm.name = this.$route.query.company_name
            this.advancedCompanySearchForm.street = this.$route.query.street
            if (data.searchType === Config.CONSTANTS.PERSON && !data.isSilentRequest) {
              this.onAdvancedSearch(this.defaultPaginationParams, true)
            }
            this.checkAdvancedSearchFields()
          }
        })

      this.filterSubscription = apiService.onQuickFilterChangedSubject.subscribe(
        data => {
          if (data) {
            if (data.reset) {
              this.resetFilterInput()
              this.resetForms(false, data.clearResult)
              if (data.form !== Config.CONSTANTS.PERSON && data.doSearch) {
                this.onAdvancedSearch()
              }
            }
          }
        }
      )
    }
  }

  resetFilterInput () {
    // Note: Changes in this variable (advancedCompanySearchForm) should update in apiService method checkForActiveFilters
    this.advancedCompanySearchForm = {
      name: '',
      street: '',
      city: '',
      zip_code: '',
      org_num: '',
      phonetic: true,
      phone: '',
      company_type: ''
    }
  }

  public hideNoResultMsg () {
    this.hideToast()
    this.searchingCompleted = false
  }

  public isCompactForm () {
    return this.page === 'result'
  }

  public canShowNoResult () {
    return (
      this.searchingCompleted &&
      this.searchResult.length === 0 &&
      this.page === 'home'
    )
  }

  public validateForm (formObj: any) {
    this.hideToast()
    // Reset error flags
    this.resetErrors()
    for (const [key] of Object.entries(formObj)) {
      // Skip validation for listed fields in the object 'fieldValidationSkipList'
      if (this.fieldValidationSkipList.indexOf(key) === -1) {
        this.validateFieldsLength(formObj, key, false)
        this.validateFieldForSpecialChar(formObj, key)
      }
    }
    return this.isFormValid
  }

  validateFieldsLength (formObj: any, field: string, isRequired: boolean) {
    if (isRequired || (!isRequired && formObj[field] && formObj[field].length > 0)) {
      const valueLength = formObj[field].length
      if (valueLength > 200) {
        this.onFieldValidationFailed(
          field,
          this.$t('default.form_error.max_char_count', {
            field: this.getPlaceholderKeyByField(field)
          })
        )
      }
    }
  }

  validateFieldForSpecialChar (formObj: any, field: string) {
    // Special characters validation
    /* eslint-disable */
    const pattern = /['!@#$%^&*()+\=\[\]{};:"\\|<>\/?~]/;
    if (formObj[field]?.match(pattern)) {
      this.onFieldValidationFailed(
        field,
        this.$t("default.form_error.no_special_char", {
          field: this.getPlaceholderKeyByField(field)
        })
      );
    }
  }

  getPlaceholderKeyByField(field: string) {
    if (field === "name") {
      return this.$t("default.table.company_name");
    } else {
      return this.$t("default.search_form." + field);
    }
  }

  onFieldValidationFailed(field: string, message: string) {
    this.formError[field] = true;
    this.isFormValid = false;
    utilityService.showToast(message, "error", "form_error");
  }

  hideToast() {
    utilityService.hideToast();
  }

  resetErrors() {
    this.isFormValid = true;
    this.formError = resetErrorData
    this.hideToast();
  }

  resetForms(emitResult = true, clearResult = true) {
    if (clearResult) {
      this.apiService.clearResult(Config.CONSTANTS.COMPANY, emitResult);
    }
    this.resetErrors();
  }

  unmounted() {
    // unsubscribe to ensure no memory leaks
    this.paginationSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.tabSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  canShowFormError(control: string) {
    return this.formError[control];
  }

  public onAdvancedSearch(
    paginationParam: IPaginationParam = this.defaultPaginationParams,
    isSilentRequest? : boolean
  ) {
    this.queryServer(
      paginationParam,
      this.advancedCompanySearchForm,
      Config.CONSTANTS.COMPANY,
      isSilentRequest
    );
  }

  public queryServer(
    paginationParam: IPaginationParam = {
      page: 1,
      page_size: this.apiService.result.company.perPageCount,
      form: Config.CONSTANTS.COMPANY
    },
    formData: any,
    url: string,
    isSilentRequest? : boolean
  ) {
    this.hideNoResultMsg();
    if (this.validateForm(formData) && !this.isCompanySearchRequestSent) {
      this.isCompanySearchRequestSent = true
      // If searchingCompleted is true and searchResult.length is 0 then show no data found msg
      this.apiService
        .getSearchResult({ ...formData, ...paginationParam }, `/${url}`, isSilentRequest)
        .then((data: any) => {
          this.isCompanySearchRequestSent = false
          this.searchResult = data.company.result;
          this.searchingCompleted = true;
          const params = getSearchResultsParams(this.$route.query, formData, paginationParam, Config.CONSTANTS.COMPANY)
          apiService.lastExecutedQuery = params

          if (!isSilentRequest) { 
            this.$router
            .push({
              name: 'Result',
              query: params
            })
          }

          this.$nextTick(()=> {
            apiService.emitNewResult(Config.CONSTANTS.COMPANY, false)
          })
        }).catch(()=> this.isCompanySearchRequestSent = false)
    }
  }

  resetSearchField(field: string) {
    if (field === 'name' || field === 'street') {
      apiService.companyFilterObject[field] = ""
    }
    this.advancedCompanySearchForm[field] = "";
    this.formError[field] = false;
  }

  canShowClearButton(field: string) {
    let value: any = this.advancedCompanySearchForm[field];
    return value && value.length;
  }

  checkAdvancedSearchFields() {
    const query = this.$route.query
    if (query.search_type === Config.CONSTANTS.COMPANY && query.name) {
      const name = query.name.toString()
      const orgNumberRegex = /^\d{10}$/;
      this.advancedCompanySearchForm.org_num = ''
      if (orgNumberRegex.test(name)) {
        this.advancedCompanySearchForm.org_num = name
        this.advancedCompanySearchForm.name = ''
      }
    }
  }
}
