import { localeName } from '@/models/enum'

export type State = {
  appLang: localeName,
}
let storedValue = localStorage.getItem('APP_LANGUAGE') as (localeName | null)
if (!Object.values(localeName).includes(storedValue as any)) {
  storedValue = null
}
export const state: State = {
  appLang: storedValue || localeName.SV
}
