import { prop, Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import jsonPathService from '@/services/json-path-service'
import { Config } from '@/config'

// Define props in a class
class Props {
  container = prop({
    type: Object,
    required: true,
    default: {}
  });
}

export default class TableContainer extends Vue.with(Props) {
  dataToDisplay: any = [];
  canShowTable = false;
  minUrlLength = 4;
  canShowDownload: boolean = false;
  showMoreOption: boolean = true;
  maxRowCount = Config.TABLE_MAX_ROW_LENGTH;
  canShowMoreOption: boolean = false;
  created () {
    this.init()
  }

  init (row?: any) {
    if (this.container.source) {
      this.canShowTable = true
      this.dataToDisplay = this.getFieldByKey(
        apiService.containerData,
        this.container.source,
        false
      )
    } else {
      this.dataToDisplay = apiService.containerData
    }
    if (!this.dataToDisplay) {
      this.dataToDisplay = []
    } else if (
      !Array.isArray(this.dataToDisplay) &&
      typeof this.dataToDisplay === 'object'
    ) {
      const result = this.dataToDisplay
      this.dataToDisplay = []
      this.dataToDisplay.push(result)
    }
    if (row && row.documentId) {
      const resultRow = this.dataToDisplay.find(
        (document: any) => document.documentId === row.documentId
      )
      if (resultRow) {
        this.validateUrl(resultRow, true)
      }
    }
    this.canShowDownload = this.hasDownloadUrlInAnyRows(this.dataToDisplay)
    this.canShowMoreOption = this.dataToDisplay.length > this.maxRowCount
  }

  getFieldByKey (source: any, accessKey: string, sanitize = true) {
    const result = jsonPathService.getValueByField(source, accessKey)
    if (sanitize) {
      return this.$sanitize(result)
    } else {
      return result
    }
  }

  getCellValue (source: any, accessKey: string) {
    const value = this.getFieldByKey(source, accessKey, false)
    if (Array.isArray(value)) {
      const result = value.toString()
      return result.length > 0 ? result : 'N/A'
    } else if (typeof value === 'number') {
      return value
    } else {
      return value || 'N/A'
    }
  }

  getColumnCount () {
    return this.container.columns.length + 1
  }

  hasDownloadUrlInAnyRows (rows: any[]) {
    if (Array.isArray(rows)) {
      const result = rows.filter(
        row => row.active_download_url?.length > this.minUrlLength
      )
      return result?.length > 0
    } else {
      return false
    }
  }

  validateUrl (row: any, isRetry = false) {
    row.validatingUrl = true
    apiService
      .get(row.active_download_url)
      .then((data: any) => {
        row.validatingUrl = false
        if (data?.success === false && !isRetry) {
          this.requestNewData(row)
        } else {
          const anchorElement = document.createElement('a')
          anchorElement.href = row.active_download_url
          anchorElement.download = row.filename
          anchorElement.click()
        }
      })
      .catch(() => {
        row.validatingUrl = false
        this.requestNewData(row)
      })
  }

  requestNewData (row: any) {
    apiService.detailsDataRefreshSubject.subscribe(() => {
      this.init(row)
    })
    this.$emit('onTableDataRefreshRequested')
  }

  toggleShowMore () {
    this.showMoreOption = !this.showMoreOption
  }
}
