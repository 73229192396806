import { GeneralActionTypes } from '@/store/modules/general/action-types'
import axios from 'axios'
import { ElLoading } from 'element-plus'
import { ILoadingInstance } from 'element-plus/lib/el-loading/src/loading.type'
import utilityService from './utility-service'
import i18n from '@/i18n'
import { useStore } from '@/store'

const store = useStore()
const ARCHIVE_URL = process.env.VUE_APP_ARCHIVE_URL

const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true
})
const errorCode = {
  unauthorized: 'UNAUTHORIZED',
  forbidden: 'FORBIDDEN',
  force_logout: 'FORCE_LOGOUT',
  internal_server_error: 'INTERNAL_SERVER_ERROR'
}
let loading: ILoadingInstance | undefined
let requestCount = 0
service.interceptors.request.use(
  config => {
    if (!(config as any)?.meta?.disableLoader) {
      requestCount++
      if (requestCount <= 1) {
        loading = ElLoading.service({})
      }
    }
    utilityService.closeNotification()
    config.headers.Accept = 'application/json'
    return config
  },
  error => {
    requestCount--
    Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    if (ARCHIVE_URL && (response?.config?.url || '').includes(ARCHIVE_URL)) {
      store.dispatch(GeneralActionTypes.SetLastKnownStatus, response.status)
    }
    requestCount--
    if (requestCount < 1) {
      loading && loading.close()
      requestCount = 0
    }
    return response
  },
  error => {
    if (ARCHIVE_URL && error.config.url.includes(ARCHIVE_URL)) {
      store.dispatch(GeneralActionTypes.SetLastKnownStatus, error.response.status)
    }
    requestCount--
    if (requestCount < 1) {
      loading && loading.close()
      requestCount = 0
    }
    if (!error.response) {
      return Promise.reject(error)
    }
    if (error.response.status === 404) {
      window.location.href = '/page-not-found'
      return Promise.reject(error)
    } else if (error.response.status === 400) {
      window.location.href = '/data-not-found'
      return Promise.reject(error)
    }
    utilityService.closeNotification()
    if (
      (error.response.status === 401 && error.response.data.action === 'force_logout') ||
      (error.response.status === 403 && error.response.data.error_code === errorCode.force_logout)
    ) {
      utilityService.handleForceLogoutResponse()
    } else if (
      (error.response.status === 403 &&
        error.response.data.error_code === errorCode.unauthorized) ||
        (error.response.status === 401 &&
          error.response.statusText.toUpperCase() === errorCode.unauthorized)
    ) {
      utilityService.handleUnauthorizedAccess()
    } else if (
      error.response.status === 403 &&
      error.response.data.error_code === errorCode.forbidden
    ) {
      utilityService.handleForbiddenResponse()
    } else if (error.response.status === 502) {
      utilityService.showToast(
        i18n.global.t('default.alert.bad_gateway'),
        'warning',
        'font-color-warning',
        0
      )
    } else if (error.response.status === 504) {
      utilityService.showToast(
        i18n.global.t('default.alert.gateway_timeout'),
        'warning',
        'font-color-warning',
        0
      )
    } else if (
      error.response.status >= 500 ||
      (error.response.status === 403 &&
        error.response.data.error_code === errorCode.internal_server_error)
    ) {
      utilityService.handleInternalServerError()
    }
    return Promise.reject(error)
  }
)

export default service
