export class Config {
  public static PAGE_TITLE = 'Basfakta - Acta Publica';
  public static DEFAULT_TABLE_PAGE_NUMBER = 1;
  public static DEFAULT_TABLE_PER_PAGE_COUNT = 10;
  public static TABLE_ROW_COUNT_ARRAY = [10, 20, 30, 40, 50, 60];
  public static CONTACT_EMAIL_TO_DISPLAY = 'support@actapublica.se';
  public static CONTACT_NUMBER_TO_DISPLAY = '+46 (0)8-55769507';
  public static CONTACT_EMAIL_TO_BIND = 'mailto:support@actapublica.se';
  public static CONTACT_NUMBER_TO_BIND = 'http://+46855769507';
  public static HOME_PAGE_URL = 'https://actapublica.se/';
  public static CHART_CONFIG = {
    BORDER_COLOR: '#043774',
    LINE_COLOR: '#dfe2e5',
    BORDER_WIDTH: 1,
    LINE_WIDTH: 0.3,
    FONT_FAMILY: 'PT Sans',
    FONT_SIZE: 14,
    NEGATIVE_BAR_COLOR: 'rgb(243 146 0)',
    MAX_BAR_WIDTH: 17
  };

  static AGE_RANGE = { minimum_age: 16, maximum_age: 110 }
  public static TABLE_MAX_ROW_LENGTH = 8
  public static APP_LIST: {name: string, link: string, identification_key: string} [] = []
  static ACTIVE_SECTION_KEY = 'APR';
  static RESULT_MAX_COUNT_FORMATTER = { count: 99, label: '99+' }
  static SHOW_HIDE_TOGGLE_BUTTON = false;
  static CONSTANTS = {
    PERSON: 'person',
    COMPANY: 'company',
    IS_MALE: 'isMale',
    IS_FEMALE: 'isFemale',
    COMPANY_INVOLVEMENT: 'company_involvement',
    IS_MARRIED: 'is_married',
    MAXIMUM_AGE: 'maximum_age',
    MINIMUM_AGE: 'minimum_age',
    GENDER: 'gender'
  };
}
