
import { Options, Vue } from 'vue-class-component'
import TopHeader from './TopHeader/TopHeader.vue'
import Search from './Search/Search.vue'
import List from './List/List.vue'

@Options({
  components: { TopHeader, Search, List }
})
export default class Result extends Vue {}
