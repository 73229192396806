import apiService from '@/services/api-service'
import { ContainerType, ICollections, IContainer } from '@/models/common'
import { ElLoading } from 'element-plus'
import { Options, Vue } from 'vue-class-component'
import ChartContainer from '../ChartContainer/ChartContainer.vue'
import DetailsHeader from '../DetailsHeader/DetailsHeader.vue'
import ListContainer from '../ListContainer/ListContainer.vue'
import TableContainer from '../TableContainer/TableContainer.vue'
import TopHeader from '../TopHeader/TopHeader.vue'
import ReportContainer from '../ReportContainer/ReportContainer.vue'
import { Config } from '@/config'
import utilityService from '@/services/utility-service'
let self: any = null

@Options({
  components: {
    DetailsHeader,
    TopHeader,
    ListContainer,
    TableContainer,
    ChartContainer,
    ReportContainer
  }
})
export default class Details extends Vue {
  title: string = '';
  subTitle: string = '';
  collections: ICollections[] = [];
  ContainerType = ContainerType;
  containers: IContainer[] = [];
  activeMenuIndex = 0;
  searchFormName = '';
  scrollStarted: boolean = false;
  collectionIndex: any;
  canShowSideMenu: boolean = true;
  loader: any
  subscription: any;
  scrollClassName = 'btn-hide';
  isDataFetched: boolean = false
  stickHeader: boolean = false;
  showHideToggleButton: boolean = Config.SHOW_HIDE_TOGGLE_BUTTON;

  created () {
    self = this
    this.searchFormName = this.$route.params.form as string
    if (this.searchFormName !== Config.CONSTANTS.PERSON && this.searchFormName !== Config.CONSTANTS.COMPANY) {
      this.searchFormName = Config.CONSTANTS.PERSON
    }
    this.getTemplateData(this.$i18n.locale)
    window.addEventListener('scroll', this.onWindowScroll)
    this.subscription = apiService.languageChangeSubject.subscribe((language: string) => {
      this.getTemplateData(language)
    })
  }

  mounted () {
    this.$nextTick(() => {
      window.scroll(0, 0)
    })
  }

  getTemplateData (languageCode: string) {
    this.collections = []
    this.containers = []
    this.getDataToDisplay()
    apiService.getCollections(this.searchFormName, languageCode).then((collections: ICollections[]) => {
      this.collections = collections
      this.findSelectedIndex()
      if (this.collections?.length > 0) {
        this.parseContainers(
          this.collections[this.collectionIndex].containers
        )
      }
    })
  }

  findSelectedIndex () {
    let indexIdentified = false; let index = 0
    while (!indexIdentified) {
      const result = this.collections[index]
      if (result && result.containers.length) {
        indexIdentified = true
        this.collectionIndex = index
      } else {
        index++
        if (this.collections.length <= index) {
          indexIdentified = true
          this.collectionIndex = 0
        }
      }
    }
  }

  getDataToDisplay (emitEvent = false) {
    apiService
      .getDetails(this.$route.params.id, this.searchFormName)
      .then((details: any) => {
        apiService.detailsDataRefreshSubject.next()
        this.$nextTick(() => {
          this.isDataFetched = true
          this.updateAnchorTags()
          this.title = details.name
          utilityService.updatePageTitle(details.name)
          if (
            details.identity_number?.formatted &&
            details.basic?.city
          ) {
            this.subTitle = `${details.identity_number.formatted}, ${details.basic.city}`
          }
        })
      })
  }

  updateAnchorTags () {
    this.$nextTick(() => {
      const containerAnchorList = document.querySelectorAll('.container-wrap a')
      this.setTargetForAnchor(containerAnchorList)
      const headerAnchorList = document.querySelectorAll('.raw-html a')
      this.setTargetForAnchor(headerAnchorList)
    })
  }

  setTargetForAnchor (anchorList: NodeListOf<Element>) {
    if (anchorList) {
      anchorList.forEach((el: Element) => {
        el.setAttribute('target', '_blank')
      })
    }
  }

  unmounted () {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe()
  }

  destroyed () {
    window.removeEventListener('scroll', this.onWindowScroll)
  }

  scrollToTop () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  onWindowScroll () {
    if (document.body.scrollTop > 97 || document.documentElement.scrollTop > 97) {
      this.stickHeader = true
    } else {
      this.stickHeader = false
    }
    if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
      this.scrollClassName = 'btn-show'
    } else {
      this.scrollClassName = 'btn-hide'
    }
    if (!self.scrollStarted) {
      let isTopItemIdentified = false
      document.querySelectorAll('.container-wrap').forEach(function (button) {
        if (!isTopItemIdentified && button.getClientRects()[0].top >= 0) {
          isTopItemIdentified = true
          self.activeMenuIndex = button.getAttribute('index')
          const div = document.getElementById('scrollable-div')
          if (div) {
            const selectedMenu = document.getElementById(
              'menu-item-' + self.activeMenuIndex
            )
            const rect:any = selectedMenu?.getBoundingClientRect()
            if (rect.bottom > window.innerHeight) {
              div.scrollTop = div.scrollTop + 85
            } else if (rect.top < 0) {
              div.scrollTop = div.scrollTop - 85
            }
          }
        }
      })
    }
  }

  /**
   * Parse container object
   *
   * @param container IContainer[]
   * @param isComposite Pass true if container type is 'composite' else false
   */

  parseContainers (
    containers: IContainer[],
    isComposite = false,
    compositeContainerIndex: null | number = null
  ) {
    containers.forEach((container: any) => {
      if (this.isAllowedInScope(container)) {
        if (this.isType(container, ContainerType.COMPOSITE)) {
          // To display composite container main title
          this.updateContainersList(
            {
              title: container.title,
              type: container.type,
              scope: container.scope,
              sub_title: container.sub_title,
              width: container.width,
              edit_url: container.edit_url
            },
            false
          )
          // Fetch containers inside composite
          const compositeContainerIndex = this.containers.length - 1
          this.parseContainers(
            container.containers,
            true,
            compositeContainerIndex
          )
        } else {
          this.updateContainersList(
            container,
            isComposite,
            compositeContainerIndex
          )
        }
      }
    })
  }

  /**
   * To update container array list
   *
   * @param container IContainer
   *
   * @param isComposite boolean
   */
  updateContainersList (
    container: IContainer,
    isComposite: boolean,
    compositeContainerIndex: null | number = null
  ) {
    container.isComposite = isComposite
    container.visible = true
    container.parentIndex = compositeContainerIndex
    if (container.parentIndex != null) {
      container.width = this.containers[container.parentIndex].width
    }
    this.containers.push(container)
  }

  /**
   * To check container scope
   *
   * @param container IContainer
   * @return  Boolean
   */
  isAllowedInScope (container: IContainer) {
    if (
      Array.isArray(container.scope) &&
      container.scope.indexOf(this.searchFormName) !== -1
    ) {
      return true
    }
    return false
  }

  /**
   * To check container can show or not
   *
   * @param container IContainer
   *
   * @param targetType string
   *
   * @return  Boolean
   */
  isType (container: IContainer, targetType: string) {
    if (container.type === targetType) {
      return true
    }
    return false
  }

  /**
   * Used to scroll to a specific container by index.
   *
   * @param index Array index of containers list
   */
  scrollToElement (index: any) {
    this.scrollStarted = true
    this.activeMenuIndex = index
    const el = document.getElementById('container' + index)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        this.scrollStarted = false
      }, 1500)
    }
  }

  switchCollection (index: number) {
    this.loader = ElLoading.service({})
    this.collectionIndex = index
    this.containers = []
    this.$nextTick(() => {
      this.parseContainers(this.collections[index].containers)
      this.loader.close()
      this.updateAnchorTags()
    })
  }

  toggleSubContainers (container: any, index: number) {
    if (this.isType(container, ContainerType.COMPOSITE)) {
      this.containers
        .filter(container => container.parentIndex === index)
        .forEach(element => {
          element.visible = container.visible
        })
    } else if (container.parentIndex !== null) {
      // To handle sub-containers toggle event, ie all the sub-containers are hidden then its composite container should be hidden
      if (
        this.containers.filter(
          item =>
            item.parentIndex === container.parentIndex &&
            item.visible !== container.visible
        ).length === 0
      ) {
        this.containers[container.parentIndex].visible = container.visible
      }
    }
  }

  toggleSideMenu () {
    this.canShowSideMenu = !this.canShowSideMenu
  }

  openContainerEditPage (editURL: any) {
    window.open(editURL, '_blank')
  }
}
