import { Config } from '@/config'
import i18n from '@/i18n'
import { ElMessage, ElMessageBox } from 'element-plus'

class UtilityService {
  toaster: any[] = [];
  notification: any

  getCompanyTypes () {
    return new Promise(resolve => {
      resolve([
        {
          name: 'select',
          id: ''
        },
        {
          name: 'limited',
          id: 'LimitedCompany'
        },
        {
          name: 'sole',
          id: 'SoleProprietorship'
        },
        {
          name: 'trading',
          id: 'TradingCompanyLimitedPartnerShip'
        },
        {
          name: 'others',
          id: 'Others'
        }
      ])
    })
  }

  hideToast () {
    if (this.toaster) {
      this.toaster.forEach(item => item.close())
    }
  }

  replaceQueryParams (currentQuery: any, newFields: any, paginationParams: any) {
    for (const key in newFields) {
      currentQuery[key] = newFields[key]
    }
    return { ...currentQuery, ...paginationParams }
  }

  showToast (
    message: string,
    type: 'error' | 'success' | 'warning',
    customClass: string,
    duration = 0,
    showClose = true
  ) {
    setTimeout(() => {
      this.toaster.push(
        ElMessage({
          message: message,
          type: type,
          offset: 95,
          duration: duration,
          showClose: showClose,
          dangerouslyUseHTMLString: true,
          customClass: customClass
        })
      )
    }, 200)
  }

  closeNotification () {
    if (this.notification) {
      this.notification.close()
    }
  }

  handleUnauthorizedAccess () {
    this.showAlertPopup(
      i18n.global.t('default.alert.logoutMessage'),
      i18n.global.t('default.alert.auth_required'),
      i18n.global.t('default.button.login'),
      this.pageRedirect,
      process.env.VUE_APP_LOGIN_REDIRECT_URL +
        '?redirect=' +
        encodeURIComponent(window.location.href)
    )
  }

  handleForceLogoutResponse () {
    this.showAlertPopup(
      i18n.global.t('default.alert.settingsChanged'),
      i18n.global.t('default.alert.auth_required'),
      i18n.global.t('default.button.login'),
      this.pageRedirect,
      process.env.VUE_APP_LOGIN_REDIRECT_URL +
        '?redirect=' +
        encodeURIComponent(window.location.href)
    )
  }

  handleForbiddenResponse () {
    this.showAlertPopup(
      i18n.global.t('default.alert.no_access'),
      i18n.global.t('default.alert.access_required'),
      i18n.global.t('default.button.request_access'),
      this.pageRedirect,
      Config.CONTACT_EMAIL_TO_BIND
    )
  }

  handleInternalServerError () {
    this.showAlertPopup(
      i18n.global.t('default.alert.server_error_details'),
      i18n.global.t('default.alert.server_error_title'),
      i18n.global.t('default.page_not_found.contact_us'),
      this.pageRedirect,
      Config.CONTACT_EMAIL_TO_BIND,
      true
    )
  }

  showAlertPopup (
    content: string,
    title: string,
    buttonText: string,
    callback: any,
    callbackParams: any,
    showClose = false
  ) {
    ElMessageBox.close()
    ElMessageBox.confirm(content, title, {
      confirmButtonText: buttonText,
      type: 'warning',
      showClose: showClose,
      showCancelButton: false,
      center: true,
      closeOnClickModal: false,
      confirmButtonClass: 'active-button',
      closeOnPressEscape: false
    }).then(() => {
      callback(callbackParams)
    })
  }

  pageRedirect (url: string) {
    window.location.href = url
  }

  updatePageTitle (title: any) {
    const pageTitle = (title) ? title + ' - ' + Config.PAGE_TITLE : Config.PAGE_TITLE
    document.title = pageTitle
  }
}

export default new UtilityService()
