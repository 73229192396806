const jsonPath = require('jsonpath')

class JsonPathService {
  getValueByField (source: any, accessKey: string) {
    if (typeof source === 'object') {
      const result = jsonPath.query(source, accessKey)
      if (result?.length !== 0) {
        if (result.length === 1) {
          return this.getFormattedValue(result[0])
        } else {
          return this.getFormattedValue(result)
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  getFormattedValue (value: any) {
    if (typeof value === 'boolean') {
      if (value) {
        return 'Yes'
      }
      return 'No'
    }
    return value
  }

  getValueArrayByField (source: any, query: string) {
    return jsonPath.query(source, query)
  }
}

export default new JsonPathService()
