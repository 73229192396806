import { PreferencesMutationTypes } from './mutation-types'
import { localeName } from '@/models/enum'
import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@/store'
import { State } from './state'
import { Mutations } from './mutations'
import { PreferencesActionTypes } from './action-types'
import apiService from '@/services/api-service'
import i18n from '@/i18n'
import { computed } from 'vue'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PreferencesActionTypes.SetAppLanguage](
    { commit }: AugmentedActionContext,
    locale: localeName,
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [PreferencesActionTypes.SetAppLanguage] ({ commit }, locale: localeName) {
    commit(PreferencesMutationTypes.SET_LANG, locale)
    apiService.languageChangeSubject.next(locale as string)
  }
}
