import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/style.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import i18n from './i18n'
import router from './router'
import { store } from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Sanitize from './commons/sanitize'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

const app = createApp(App).use(store).use(router).use(i18n).use(VueAxios, axios).use(ElementPlus).use(Sanitize)
app.mount('#app')
