import { Config } from '@/config'
import apiService from '@/services/api-service'
import jsonPathService from '@/services/json-path-service'
import { prop, Vue } from 'vue-class-component'

// Define props in a class
class Props {
  container = prop({
    type: Object,
    required: true,
    default: {}
  });
}

export default class ListContainer extends Vue.with(Props) {
  dataToDisplay = {}
  showMoreOption: boolean = true;
  maxRowCount = Config.TABLE_MAX_ROW_LENGTH;
  canShowMoreOption: boolean = false;

  created () {
    if (this.container.source) {
      this.dataToDisplay = this.getFieldByKey(this.container.source, apiService.containerData)
    } else {
      this.dataToDisplay = apiService.containerData
    }
    this.canShowMoreOption = this.container.rows.length > this.maxRowCount
  }

  getFieldByKey (accessKey: string, source = null):any {
    if (source) {
      return this.$sanitize(jsonPathService.getValueByField(source, accessKey))
    } else {
      return this.$sanitize(jsonPathService.getValueByField(this.dataToDisplay, accessKey))
    }
  }

  toggleShowMore () {
    this.showMoreOption = !this.showMoreOption
  }
}
