import { Config } from '@/config'
import apiService from '@/services/api-service'
import { IResultGroup } from '@/models/common'
import { Vue, Options } from 'vue-class-component'
import PersonAdvanceSearch from '@/components/PersonAdvanceSearch/PersonAdvanceSearch.vue'
import CompanyAdvanceSearch from '@/components/CompanyAdvanceSearch/CompanyAdvanceSearch.vue'
import utilityService from '@/services/utility-service'
import WordHighlighter from 'vue-word-highlighter'

@Options({
  components: { PersonAdvanceSearch, CompanyAdvanceSearch, WordHighlighter }
})
export default class List extends Vue {
  CONSTANTS = Config.CONSTANTS
  Config = Config
  public result: IResultGroup | any = {
    person: {
      result: [],
      totalResults: 0,
      currentPageNumber: 1,
      perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT,
      displayCount: null
    },
    company: {
      result: [],
      totalResults: 0,
      currentPageNumber: 1,
      perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT,
      displayCount: null
    }
  };

  private subscription: any;
  public currentSearchForm = this.CONSTANTS.PERSON;
  tableSizeList = Config.TABLE_ROW_COUNT_ARRAY;
  totalResult: number = 0;
  apiService = apiService;
  prevButtonText = '';
  nextButtonText = '';
  paginationLayout = '';
  isAdvancedSearch: boolean = false;
  activeTab = this.CONSTANTS.PERSON;
  extractedSearchInput = { name: '', address: '' };
  age = Config.AGE_RANGE;
  isFilterActive: { person: boolean; company: boolean } = {
    person: false,
    company: false
  };

  quickFilter: any = {};

  searchOptionSubscription: any;
  tabDisplay: any = { person: true, company: true };

  mounted () {
    const query = this.$route.query
    this.resetQuickFilter(false)
    this.subscription = apiService
      .getSubscribableResultObject()
      .subscribe(data => {
        if (data) {
          if (data.isSingleSearch) {
            this.$nextTick(() => {
              this.activeTab = data.searchType
              this.resetQuickFilter(false)
              this.isAdvancedSearch = false
            })
          }
          this.currentSearchForm = apiService.currentSearchForm
          this.result = data.result
          this.$nextTick(() => {
            this.isFilterActive = { ...apiService.isFilterActive }
            this.setResultCount()
            if (data.searchType === this.activeTab) {
              this.scrollToResultSection()
            }
          })
        }
        this.closeValidationErrors()
        if (data.searchType === this.CONSTANTS.PERSON) {
          const params =
              apiService.lastExecutedQuery || (this.$route.query as any)
          this.quickFilter.company_involvement = this.getBooleanValue(
            params.company_involvement
          )
          this.quickFilter.is_married = this.getBooleanValue(params.is_married)
          this.quickFilter.gender = params.gender
          if (params.gender) {
            this.quickFilter.isMale = params.gender === 'male'
            this.quickFilter.isFemale = params.gender === 'female'
          }
          if (params.minimum_age === undefined) {
            this.quickFilter.maximum_age = this.age.maximum_age
            this.quickFilter.minimum_age = this.age.minimum_age
          } else {
            this.quickFilter.maximum_age = params.maximum_age
            this.quickFilter.minimum_age = params.minimum_age
          }
        }
      })

    if (
      query.page_size &&
      Config.TABLE_ROW_COUNT_ARRAY.indexOf(Number(query.page_size)) !== -1
    ) {
      apiService.result.person.perPageCount = Number(query.page_size)
      apiService.result.company.perPageCount = Number(query.page_size)
    }

    if (query.advanced === 'true') {
      this.isAdvancedSearch = true
    }
    if (query.company === 'false') {
      this.tabDisplay.company = false
    }

    if (query.person === 'false') {
      this.tabDisplay.person = false
    }
    if (query.search_type === undefined) {
      this.activeTab = this.tabDisplay.person
        ? this.CONSTANTS.PERSON
        : this.CONSTANTS.COMPANY
    } else {
      this.activeTab =
        query.search_type === this.CONSTANTS.PERSON
          ? this.CONSTANTS.PERSON
          : this.CONSTANTS.COMPANY
    }
    this.extractedSearchInput.name = query.name as string
    this.extractedSearchInput.address = query.address as string

    this.setPaginationParams()
    window.addEventListener('resize', this.setPaginationParams, true)
    this.searchOptionSubscription = apiService.onSearchOptionChanged.subscribe(
      data => {
        this.tabDisplay = data
        if (
          this.activeTab === this.CONSTANTS.PERSON &&
          this.tabDisplay.company
        ) {
          this.activeTab = this.CONSTANTS.COMPANY
        } else if (
          this.activeTab === this.CONSTANTS.COMPANY &&
          this.tabDisplay.person
        ) {
          this.activeTab = this.CONSTANTS.PERSON
        }
        this.$nextTick(() => {
          apiService.onResultTabChangedSubject.next({ tabName: this.activeTab, isSilentRequest: false, doSearch: true })
        })
      }
    )
    this.$nextTick(() => {
      apiService.onResultTabChangedSubject.next({ tabName: this.CONSTANTS.PERSON, isSilentRequest: true, doSearch: true })
      apiService.onResultTabChangedSubject.next({ tabName: this.CONSTANTS.COMPANY, isSilentRequest: true, doSearch: true })
    })
  }

  scrollToResultSection () {
    this.$nextTick(() => {
      if (this.$el !== null) {
        let tableId = '[data-id=company-table]'
        if (this.activeTab === this.CONSTANTS.PERSON) {
          tableId = '[data-id=person-table]'
        }
        const tableElement = this.$el.querySelector(tableId)
        if (tableElement) {
          window.scroll(0, tableElement.offsetTop)
        } else {
          window.scroll(0, this.$el.offsetTop)
        }
      }
    })
  }

  closeValidationErrors () {
    utilityService.hideToast()
  }

  getBooleanValue (value: any): boolean {
    if (value !== undefined) {
      value = value.toString()

      if (value === 'true') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  setPaginationParams () {
    if (window.innerWidth < 768) {
      this.prevButtonText = this.$t('default.pagination.prev_btn')
      this.nextButtonText = this.$t('default.pagination.next_btn')
      this.paginationLayout = 'prev, next'
    } else {
      this.prevButtonText = '«'
      this.nextButtonText = '»'
      this.paginationLayout = 'prev, pager, next'
    }
  }

  unmounted () {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe()
    this.searchOptionSubscription.unsubscribe()
  }

  public isPersonSearch () {
    return this.currentSearchForm === this.CONSTANTS.PERSON
  }

  public isCompanySearch () {
    return this.currentSearchForm === this.CONSTANTS.COMPANY
  }

  public onRowClick (id: string, form: string) {
    this.closeValidationErrors()
    apiService.isInitialSearchDone = true
    this.$router.push({ name: 'Details', params: { form: form, id: id } })
  }

  handlePerPageChange (type: string) {
    this.sendPaginationEvent(type)
  }

  currentPageChangeHandler (type: string) {
    this.sendPaginationEvent(type)
  }

  sendPaginationEvent (type: string) {
    apiService.paginationSubject.next({
      page: this.result[type].currentPageNumber,
      page_size: this.result[type].perPageCount,
      form: type
    })
  }

  getLastRowIndex (
    perPageCount: number,
    currentPageNumber: number,
    totalResults: number
  ) {
    const lastRowIndex = perPageCount * currentPageNumber
    if (lastRowIndex > totalResults) {
      return totalResults
    } else {
      return lastRowIndex
    }
  }

  toggleAdvancedFilter () {
    this.isAdvancedSearch = !this.isAdvancedSearch
    const params = Object.assign({}, this.$route.query)
    params.advanced = this.isAdvancedSearch as any
    this.$router.push({
      name: 'Result',
      query: params
    })
  }

  onTabSelect (event: any) {
    this.closeValidationErrors()
    this.activeTab = event.paneName
    this.scrollToResultSection()
  }

  onAgeKeyPress (event: any) {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault()
    }
  }

  onAgeChanged (field: string) {
    if (
      this.quickFilter[field] < this.age.minimum_age ||
      this.quickFilter[field] > this.age.maximum_age ||
      !this.quickFilter[field]
    ) {
      if (field === this.CONSTANTS.MAXIMUM_AGE) {
        this.quickFilter[field] = this.age.maximum_age
      } else {
        this.quickFilter[field] =
          this.quickFilter[field] > this.age.maximum_age
            ? this.age.maximum_age
            : this.age.minimum_age
      }
    }
    this.onFilterChanged(field)
  }

  onFilterChanged (field: string, direct = false) {
    if (
      field !== this.CONSTANTS.MINIMUM_AGE &&
      field !== this.CONSTANTS.MAXIMUM_AGE
    ) {
      this.quickFilter[field] = !this.quickFilter[field]
    } else if (direct) {
      if (field === this.CONSTANTS.MINIMUM_AGE) {
        this.onAgeChanged(this.CONSTANTS.MINIMUM_AGE)
      } else if (field === this.CONSTANTS.MAXIMUM_AGE) {
        this.onAgeChanged(this.CONSTANTS.MAXIMUM_AGE)
      }
      return
    }
    if (
      field === this.CONSTANTS.IS_MALE ||
      field === this.CONSTANTS.IS_FEMALE
    ) {
      field = this.CONSTANTS.GENDER
      if (
        (this.quickFilter.isMale && this.quickFilter.isFemale) ||
        (!this.quickFilter.isMale && !this.quickFilter.isFemale)
      ) {
        this.quickFilter[field] = ''
      } else if (this.quickFilter.isMale) {
        this.quickFilter[field] = 'male'
      } else if (this.quickFilter.isFemale) {
        this.quickFilter[field] = 'female'
      }
    }

    if (!this.quickFilter.company_involvement) {
      this.quickFilter.company_involvement = ''
    }
    if (!this.quickFilter.is_married) {
      this.quickFilter.is_married = ''
    }
    apiService.onQuickFilterChangedSubject.next({
      key: field,
      value: this.quickFilter[field],
      form: this.CONSTANTS.PERSON,
      doSearch: true
    })
  }

  resetQuickFilter (reload: boolean) {
    this.quickFilter = {
      company_involvement: '',
      is_married: '',
      minimum_age: this.age.minimum_age,
      maximum_age: this.age.maximum_age,
      isMale: false,
      isFemale: false,
      gender: ''
    }
    if (reload) {
      apiService.lastExecutedQuery = {}
      apiService.personFilterObj.name = ''
      apiService.personFilterObj.address = ''
      apiService.companyFilterObject.name = ''
      apiService.companyFilterObject.street = ''
      this.isFilterActive = { person: false, company: false }
      this.apiService.isFilterActive = { person: false, company: false }
      apiService.onQuickFilterChangedSubject.next({
        reset: true,
        form: '',
        doSearch: false,
        clearResult: false
      })
    }
  }

  onAgeFilterApply () {
    apiService.onQuickFilterChangedSubject.next({
      key: this.CONSTANTS.MINIMUM_AGE,
      value: this.quickFilter[this.CONSTANTS.MINIMUM_AGE],
      form: '',
      doSearch: false
    })
    apiService.onQuickFilterChangedSubject.next({
      key: this.CONSTANTS.MAXIMUM_AGE,
      value: this.quickFilter[this.CONSTANTS.MAXIMUM_AGE],
      form: this.CONSTANTS.PERSON,
      doSearch: true
    })
  }

  setResultCount () {
    if (apiService.showCountLoader.person) {
      this.result.person.displayCount = null
    } else {
      this.result.person.displayCount = this.getResultCountForBadge(this.result.person.totalResults)
    }
    if (apiService.showCountLoader.company) {
      this.result.company.displayCount = null
    } else {
      this.result.company.displayCount = this.getResultCountForBadge(this.result.company.totalResults)
    }
    const query = this.$route.query
    if (!this.result.person.displayCount && this.result.company.displayCount && query.company === 'true') {
      this.activeTab = this.CONSTANTS.COMPANY
    }
  }

  getResultCountForBadge (count: number) {
    if (count > Config.RESULT_MAX_COUNT_FORMATTER.count) {
      return Config.RESULT_MAX_COUNT_FORMATTER.label
    }
    return count
  }

  canShowCountLoader (value: number) {
    return value === null
  }
}
