{
    "search_form": {
        "welcome": "Welcome. What do you want to search for?",
        "person": "Person",
        "company": "Company",
        "name": "Name",
        "address": "Address",
        "last_name": "Surname",
        "street": "Street address",
        "zip_code": "Zipcode",
        "ssn": "Social security number",
        "municipality": "Post office",
        "phonetic": "Phonetic search",
        "phone": "Phone number",
        "search": "SEARCH",
        "org_num": "Organisation number",
        "company_involvement": "Has company involvement",
        "lakhs_corp_commitment": "Lacks corporate commitment",
        "married": "Married",
        "unmarried": "Unmarried",
        "male": "Male",
        "female": "Female",
        "minimum_age": "Min Age",
        "maximum_age": "Max Age",
        "city": "City",
        "more_details": "More options",
        "birth_year": "Year of birth"
    },
    "alert": {
        "no_result": "No result found",
        "not_found_header": "Page not found",
        "not_found_detail": "We're sorry, the page you requested could not be found",
        "logoutMessage": "You have been logged out from Actapublica. Please log in again to continue.",
        "auth_required": "Login Required",
        "no_record_found": "No records found",
        "no_access": "You don't have access to the APR section",
        "access_required": "APR access required",
        "server_error_title": "Internal Server Error",
        "server_error_details": "The server encountered an internal error or misconfiguration and was unable to complete your request.",
        "no_data": "No data is available to display",
        "bad_gateway": "We received an invalid response from the upstream server. Please try again",
        "gateway_timeout": "Server taking too long to respond. Please try again",
        "settingsChanged": "We've recently made some changes to your access to the service. You've been logged out. We request you to login again so that those changes will take effect immediately.",
        "network_unavailable": "You are offline, Please check your network connection"
    },
    "result_page": {
        "title": "Search result",
        "footer": "Showing { firstRowIndex} to { lastRowIndex } of { totalResultCount } hits",
        "show_page_count": "SHOW",
        "count": "HITS"
    },
    "table": {
        "ssn": "SSN",
        "first_name": "First name",
        "last_name": "Last name",
        "address": "Address",
        "city": "City",
        "org_id": "Org. ID",
        "company_name": "Company Name",
        "company_code": "Company Code",
        "street": "Street"
    },
    "details_page": {
        "go_back": "Back to search results",
        "print": "Print PDF report",
        "download": "Download",
        "back_to_home": "Back to home"
    },
    "form_error": {
        "no_special_char": "Only accept hyphen(-) and underscore(_) as special characters in '{field}'",
        "birth_year": "Year of birth should be in between {min} and {max}",
        "name_or_address": "Either name or address required",
        "one_char_count": "Please enter some search keywords",
        "max_char_count": "Maximum 200 characters are allowed"
    },
    "button": {
        "login": "Log In",
        "advanced_search": "Advanced Search",
        "hide": "Hide",
        "request_access": "Request Access",
        "annual_report": "Annual Report",
        "show_more": "Show more",
        "show_less": "Show less"
    },
    "pagination": {
        "prev_btn": "« Previous",
        "next_btn": "Next »"
    },
    "page_not_found": {
        "title": "Oops! The page is not here unfortunately.",
        "back_to_home": "Go to the homepage",
        "contact_us": "Contact us"
    },
    "data_not_found": {
        "title": "Oops! The requested data is not found."
    },
    "company_types": {
        "select": "Select Company type",
        "limited": "Limited Company",
        "sole": "Sole Proprietorship",
        "trading": "Trading Company Limited Partnership",
        "others": "Others"
    },
    "select_options": {
        "month": "Month of birth",
        "marital_status": "Marital status",
        "gender": "Gender",
        "company_involvement": "Company Involvement",
        "birth_day": "Day of birth",
        "municipality": "Municipality",
        "county": "State"
    },
    "months": {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    },
    "label": {
        "years": "Years",
        "age_group": "Age Group",
        "person_result": "Persons",
        "company_result": "Companies",
        "available_report": "Available report(s)",
        "loading": "Loading..",
        "sort_by": "Filter by",
        "age_from": "Age from",
        "to": "to",
        "reset": "Reset"
    }
}
