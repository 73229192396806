import { MutationTree } from 'vuex'

import { State } from './state'
import { GeneralMutationTypes } from './mutation-types'

export type Mutations<S = State> = {
  [GeneralMutationTypes.SET_LAST_KNOWN_STATUS](state: S, status: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [GeneralMutationTypes.SET_LAST_KNOWN_STATUS] (state: State, status: number) {
    state.lastKnownStatus = status
  }
}
