import service from '@/services/request'

class PreviewService {
  previewData = {}
  getPreviewData (id: string, view: string, language: string) {
    return new Promise((resolve, reject) => {
      service
        .get(process.env.VUE_APP_ARCHIVE_API_URL + '/preview-data/' + language + '/' + view + '/' + id)
        .then(response => {
          if (response.data) {
            this.previewData = response.data
          } else {
            this.previewData = {}
          }
          resolve(this.previewData)
        })
        .catch(error => {
          this.previewData = {}
          reject(error)
        })
    })
  }
}

export default new PreviewService()
