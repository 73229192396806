import { ICollections, IPaginationParam, IResultGroup } from '@/models/common'
import { Subject } from 'rxjs'
import service from '@/services/request'
import { Config } from '@/config'

class ApiService {
  public currentFilter = {};
  public isInitialSearchDone = false;
  age = Config.AGE_RANGE;
  private searchResultSubject = new Subject<{
    result: IResultGroup;
    searchType: string;
    isSingleSearch: boolean;
    isSilentRequest: boolean;
  }>();

  public detailsDataRefreshSubject = new Subject();

  public onResultTabChangedSubject = new Subject<{
    isSilentRequest? : boolean,
    tabName: string,
    doSearch?: boolean
  }>();

  public onQuickFilterChangedSubject = new Subject<{
    doSearch: boolean;
    key?: string;
    value?: string;
    reset?: boolean;
    form?: string;
    clearResult?: boolean;
  }>();

  public onSectionListFetchedSubject = new Subject();
  public result: IResultGroup | any = {
    person: {
      result: [],
      totalResults: 0,
      currentPageNumber: 1,
      perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT,
      displayCount: null
    },
    company: {
      result: [],
      totalResults: 0,
      currentPageNumber: 1,
      perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT,
      displayCount: null
    }
  };

  public languageChangeSubject = new Subject<string>();

  public currentSearchForm = Config.CONSTANTS.PERSON;
  containerData: any = {};

  public paginationSubject = new Subject<IPaginationParam>();
  lastExecutedQuery: any;
  onSearchOptionChanged = new Subject<{ person: boolean; company: boolean }>();
  isFilterActive: { person: boolean; company: boolean } | any = {
    person: false,
    company: false
  };

  personFilterObj = {
    name: '',
    address: '',
    zip_code: '',
    ssn: '',
    last_name: '',
    phonetic: 'true',
    phone: '',
    birth_year: '',
    county_code: '',
    municipality_code: '',
    birth_month: '',
    birth_day: '',
    company_involvement: '',
    is_married: '',
    gender: '',
    minimum_age: this.age.minimum_age.toString(),
    maximum_age: this.age.maximum_age.toString()
  };

  companyFilterObject = {
    name: '',
    street: '',
    city: '',
    zip_code: '',
    org_num: '',
    phonetic: 'true',
    phone: '',
    company_type: ''
  };

  showCountLoader: any = {
    person: false,
    company: false
  };

  resetResult () {
    this.result = {
      person: {
        result: [],
        totalResults: 0,
        currentPageNumber: 1,
        perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT
      },
      company: {
        result: [],
        totalResults: 0,
        currentPageNumber: 1,
        perPageCount: Config.DEFAULT_TABLE_PER_PAGE_COUNT
      }
    }
  }

  getSearchResult (params: any, endpoint: string, isSilentRequest?: boolean) {
    this.isInitialSearchDone = true
    this.showCountLoader[params.form] = isSilentRequest
    return new Promise((resolve, reject) => {
      // Create query param string
      this.checkForActiveFilters(params)
      const queryString = Object.keys(params)
        .map(key => key + '=' + (params[key] !== undefined ? params[key] : ''))
        .join('&')
      service
        .get(process.env.VUE_APP_API + endpoint + '?' + queryString, {
          meta: {
            disableLoader: isSilentRequest
          }
        } as any)
        .then(response => {
          let formName = Config.CONSTANTS.COMPANY
          if (params.type === Config.CONSTANTS.PERSON) {
            formName = Config.CONSTANTS.PERSON
          }
          this.showCountLoader[formName] = false
          if (response.data?.data.results) {
            this.result[formName].result = response.data.data.results
            this.result[formName].totalResults =
              response.data.data.total_results
            this.result[formName].currentPageNumber =
              response.data.data.current_page
          } else {
            this.result[formName].result = []
          }
          this.result[formName].isSilentRequest = isSilentRequest
          resolve(this.result)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  checkForActiveFilters (params: any) {
    const personFilter = { ...this.personFilterObj }
    const companyFilter = { ...this.companyFilterObject }
    let filterBaseValue: any = personFilter
    if (params.form === Config.CONSTANTS.COMPANY) {
      filterBaseValue = companyFilter
    }
    this.isFilterActive[params.form] = false
    Object.entries(params).forEach(([key, val]) => {
      val = val ? (val as any).toString() : val
      /* eslint-disable */
      if (filterBaseValue.hasOwnProperty(key) && filterBaseValue[key] != val) {
        this.isFilterActive[params.form] = true;
      }
    });
  }

  getSubscribableResultObject() {
    // To get the subscribable object
    return this.searchResultSubject;
  }

  emitNewResult(searchType: string, isSingleSearch = false) {
    this.searchResultSubject.next({
      result: this.result,
      searchType: searchType,
      isSingleSearch: isSingleSearch,
      isSilentRequest: this.result[searchType].isSilentRequest
    });
  }

  getDetails(id: any, searchFormName: string) {
    return new Promise(resolve => {
      service
        .get(process.env.VUE_APP_API + "/" + searchFormName + "/" + id)
        .then(response => {
          if (response.data?.data) {
            resolve(response.data.data);
            this.containerData = response.data.data;
          } else {
            resolve({});
          }
        });
    });
  }

  clearResult(form: string, emitChange = true) {
    this.result[form].result = [];
    this.result[form].perPageCount = Config.DEFAULT_TABLE_PER_PAGE_COUNT;
    this.result[form].totalResults = 0
    if (emitChange) {
      this.emitNewResult(form);
    }
  }

  getCollections(scope: string, language: string) {
    return new Promise<ICollections[]>(resolve => {
      service
        .get(
          process.env.VUE_APP_ARCHIVE_URL +
            "/apr/get-collection?scope=" +
            scope +
            "&language=" +
            language
        )
        .then(response => {
          if (response) {
            resolve(response.data);
          } else {
            resolve([]);
          }
        });
    });
  }

  getCommonData() {
    return new Promise(resolve => {
      service.get(process.env.VUE_APP_API + "/address-data").then(response => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          resolve([]);
        }
      });
    });
  }

  get(url: string) {
    return new Promise((resolve, reject) => {
      service
        .get(url)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getAnnualReports(id: string, language: string) {
    return new Promise((resolve, reject) => {
      service
        .get(
          process.env.VUE_APP_API +
            "/company/annual-reports/" +
            id +
            "?language=" +
            language
        )
        .then(response => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getReportPath(endpoint: string) {
    return new Promise((resolve, reject) => {
      service
        .get(process.env.VUE_APP_API + endpoint)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getReportFileAsBlob(url: string) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          responseType: "blob"
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getSingleSearchResult(searchType: string, searchTerm: string) {
    this.isInitialSearchDone = true;
    this.isFilterActive = { person: false, company: false };
    return new Promise((resolve, reject) => {
      service
        .get(
          process.env.VUE_APP_API +
            "/search?text=" +
            searchTerm +
            "&search_type=" +
            searchType
        )
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getConfigurations() {
    return service
      .get(process.env.VUE_APP_ARCHIVE_URL + "/configurations")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response?.status;
      });
  }

  checkAuthentication() {
    this.getConfigurations().then(response => {
      if (response.data && response.data.organisation_sections) {
        Config.APP_LIST = response.data.organisation_sections;
        this.onSectionListFetchedSubject.next();
      }
    });
  }
}

export default new ApiService();
