import apiService from '@/services/api-service'
import previewService from '@/services/preview-service'
import { ContainerType, IContainer } from '@/models/common'
import { ElLoading, ElMessage } from 'element-plus'
import { Options, Vue } from 'vue-class-component'
import ChartContainer from '../ChartContainer/ChartContainer.vue'
import ListContainer from '../ListContainer/ListContainer.vue'
import TableContainer from '../TableContainer/TableContainer.vue'
import { Config } from '@/config'

let self:any = null
@Options({
  components: {
    ListContainer,
    TableContainer,
    ChartContainer
  }
})
export default class Preview extends Vue {
  Config = Config
  activeTab = Config.CONSTANTS.PERSON;
  containers:any[] = [];
  ContainerType = ContainerType;
  dataToDisplay: any = {}
  loader: any
  showTab: boolean = false

  mounted () {
    self = this
    this.$i18n.locale = this.$route.params.language as string
    previewService
      .getPreviewData(
          this.$route.params.id as string,
          this.$route.params.view as string,
          this.$i18n.locale
      )
      .then((data: any) => {
        this.dataToDisplay = data.data
        this.parseContainers(data.template as IContainer[])
        this.selectTab(this.dataToDisplay)
        this.onTabSelected({ paneName: this.activeTab })
      })
  }

  parseContainers (
    containers: IContainer[],
    isComposite = false,
    compositeContainerIndex: null | number = null
  ) {
    containers.forEach((container: any) => {
      if (this.canShowContianer(container, ContainerType.COMPOSITE)) {
        // To display composite container main title
        this.updateContainersList(
          {
            title: container.title,
            type: container.type,
            scope: container.scope,
            sub_title: container.sub_title
          },
          false
        )
        // Fetch containers inside composite
        const compositeContainerIndex = this.containers.length - 1
        this.parseContainers(
          container.containers,
          true,
          compositeContainerIndex
        )
      } else {
        this.updateContainersList(
          container,
          isComposite,
          compositeContainerIndex
        )
      }
    })
  }

  updateContainersList (
    container: IContainer,
    isComposite: boolean,
    compositeContainerIndex: null | number = null
  ) {
    container.isComposite = isComposite
    container.visible = true
    container.parentIndex = compositeContainerIndex
    this.containers.push(container)
  }

  selectTab (dataToDisplay: any) {
    this.showTab = true
    if (dataToDisplay.person) {
      this.activeTab = Config.CONSTANTS.PERSON
    } else if (dataToDisplay.company) {
      this.activeTab = Config.CONSTANTS.COMPANY
    } else {
      this.showTab = false
      ElMessage({
        message: this.$t('default.alert.no_data'),
        type: 'info',
        offset: 95,
        duration: 0,
        showClose: true
      })
    }
  }

  canShowContianer (container: IContainer, targetType: string) {
    if (container.type === targetType && this.dataToDisplay) {
      if (this.isAllowedInScope(container)) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  onTabSelected (tab: any) {
    apiService.containerData = {}
    apiService.containerData = self.dataToDisplay[tab.paneName]
    setTimeout((containers) => {
      self.containers = containers
      self.loader.close()
    }, 500, this.containers)

    this.containers = []
    this.loader = ElLoading.service()
  }

  isAllowedInScope (container: IContainer) {
    if (
      Array.isArray(container.scope) &&
      container.scope.indexOf(this.activeTab) !== -1
    ) {
      return true
    }
    return false
  }
}
