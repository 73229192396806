import { Config } from '@/config'
import apiService from '@/services/api-service'
import utilityService from '@/services/utility-service'
import { IPaginationParam } from '@/models/common'

export interface SingleSearchParams {
  search_type: string,
  company_name: string,
  person_name: string,
  name: string,
  address: string,
  street: string,
  input: string,
  company: boolean,
  person: boolean
}

export interface SearchType {
  person: boolean,
  company: boolean
}

export const resetErrorData = {
  name: false,
  address: false,
  zip_code: false,
  ssn: false,
  phonetic: true,
  phone: false,
  org_num: false,
  company_type: false
}

export const getSingleSearchResults = (data: any, searchType: SearchType): any => {
  const responseType = data.search_type === Config.CONSTANTS.PERSON ? Config.CONSTANTS.PERSON : Config.CONSTANTS.COMPANY
  const singleSearchParams: SingleSearchParams = {
    search_type: responseType,
    company_name: data.name,
    person_name: data.name,
    name: data.name,
    address: data.address,
    street: data.address,
    input: data.input,
    company: searchType.company,
    person: searchType.person
  }
  apiService.result[responseType].result = data.results.results
  apiService.result[responseType].totalResults = data.results.total_results
  apiService.result[responseType].currentPageNumber = Config.DEFAULT_TABLE_PAGE_NUMBER
  apiService.personFilterObj.name = data.name
  apiService.personFilterObj.address = data.address
  apiService.companyFilterObject.name = data.name
  apiService.companyFilterObject.street = data.address
  apiService.lastExecutedQuery = singleSearchParams
  return [
    singleSearchParams,
    apiService,
    responseType
  ]
}

export const getSearchResultsParams = (query: any, formData: any, paginationParam: any, type: string): any => {
  const searchResultParams = utilityService.replaceQueryParams(
    Object.assign({}, query),
    formData,
    paginationParam
  )
  searchResultParams.search_type = type
  if (type === Config.CONSTANTS.PERSON) {
    searchResultParams.person_name = searchResultParams.name
    searchResultParams.person_zip_code = searchResultParams.zip_code
    searchResultParams.person_phone = searchResultParams.phone
    searchResultParams.person_phonetic = searchResultParams.phonetic
  } else if (type === Config.CONSTANTS.COMPANY) {
    searchResultParams.company_name = searchResultParams.name
    searchResultParams.company_zip_code = searchResultParams.zip_code
    searchResultParams.company_phone = searchResultParams.phone
    searchResultParams.company_phonetic = searchResultParams.phonetic
  }
  delete searchResultParams.name

  return searchResultParams
}

export const getAdvancedSearchFormValues = (query: any, advancedSearchForm: any, type: string): any => {
  for (const [key, value] of Object.entries(query)) {
    if (Object.prototype.hasOwnProperty.call(advancedSearchForm, key)) {
      advancedSearchForm[key] = value
    } else if (key === type + '_name') {
      advancedSearchForm.name = value
    } else if (key === type + '_zip_code') {
      advancedSearchForm.zip_code = value
    } else if (key === type + '_phone') {
      advancedSearchForm.phone = value
    } else if (key === type + '_phonetic') {
      advancedSearchForm.phonetic = value
    }
  }

  return advancedSearchForm
}

export const processPaginationSubscription = (pageElement: any, type: string): any => {
  return apiService.paginationSubject.subscribe(
    (paginationSubscriptionData: IPaginationParam) => {
      if (paginationSubscriptionData?.form === type) {
        pageElement.onAdvancedSearch(paginationSubscriptionData)
      }
    }
  )
}

export const processTabSubscription = (pageElement: any, type: string): any => {
  return apiService.onResultTabChangedSubject.subscribe(
    tabSubscriptionData => {
      if (tabSubscriptionData.tabName === type) {
        if (
          (type === Config.CONSTANTS.PERSON && !apiService.result.person.result.length && tabSubscriptionData.doSearch) ||
          (type === Config.CONSTANTS.COMPANY && !apiService.result.company.result.length && tabSubscriptionData.doSearch)) {
          pageElement.onAdvancedSearch(pageElement.defaultPaginationParams, tabSubscriptionData.isSilentRequest)
        } else {
          apiService.emitNewResult(type, false)
        }
      }
    }
  )
}
