import { GeneralMutationTypes } from './mutation-types'
import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@/store'
import { State } from './state'
import { Mutations } from './mutations'
import { GeneralActionTypes } from './action-types'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [GeneralActionTypes.SetLastKnownStatus](
    { commit }: AugmentedActionContext,
    status: number,
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [GeneralActionTypes.SetLastKnownStatus] ({ commit }, status: number) {
    commit(GeneralMutationTypes.SET_LAST_KNOWN_STATUS, status)
  }
}
