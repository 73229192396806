import { localeName } from '@/models/enum'
import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './state'

const successStatus = 200
const lowestErrorStatus = 400

export type Getters = {
  lastKnownStatusIsError(state: State): boolean;
}

export const getters: GetterTree<State, RootState> & Getters = {
  lastKnownStatusIsError: (state) => (state.lastKnownStatus || successStatus) >= lowestErrorStatus
}
