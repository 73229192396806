import { localeName } from '@/models/enum'
import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './state'

export type Getters = {
  getActiveLangauge(state: State): localeName;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getActiveLangauge: (state) => state.appLang
}
