import { prop, Vue } from 'vue-class-component'
import apiService from '@/services/api-service'
import jsonPathService from '@/services/json-path-service'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  ChartItem
} from 'chart.js'
import { Config } from '@/config'
let self: any = null
// Define props in a class
class Props {
  container = prop({
    type: Object,
    required: true,
    default: {}
  });

  index = prop({
    type: Number,
    required: true,
    default: 0
  });
}

export default class ChartContainer extends Vue.with(Props) {
  dataToDisplay = {};
  self = this;
  config = Config.CHART_CONFIG;
  chartObject: any
  hasError: boolean = false;
  created () {
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip
    )
    if (this.container.source) {
      this.dataToDisplay = this.getFieldByKey(
        apiService.containerData,
        this.container.source,
        false
      )
    } else {
      this.dataToDisplay = apiService.containerData
    }
  }

  mounted () {
    const ctx = document.getElementById(this.index + 'chart') as ChartItem
    if (ctx) {
      try {
        const labels = jsonPathService.getValueArrayByField(
          this.dataToDisplay,
          this.container.chart_labels
        )
        const data: any = {
          labels: this.formatLabels(labels),
          datasets: []
        }
        this.container.chart.forEach((dataset: any) => {
          const dataToBeDisplayed = jsonPathService.getValueArrayByField(
            this.dataToDisplay,
            dataset.key
          )
          data.datasets.push({
            label: dataset.label,
            data: dataToBeDisplayed,
            backgroundColor: this.getBGcolor(
              dataset.bg_color,
              dataToBeDisplayed
            ),
            borderColor: this.getBGcolor(
              dataset.border_color,
              dataToBeDisplayed
            ),
            maxBarThickness: this.config.MAX_BAR_WIDTH
          })
        })
        self = this
        const config: any = {
          type: this.container.chart_type,
          data: data
        }
        if (this.container.chart_type === 'bar' || this.container.chart_type === 'line') {
          config.options = {
            scales: {
              y: {
                grid: {
                  borderColor: this.config.BORDER_COLOR, // grid border color
                  borderWidth: this.config.BORDER_WIDTH, // grid border width in px
                  color: function (context: any) {
                  // set color for the line at 0 tick
                    if (context.tick.value === 0) {
                      return self.config.BORDER_COLOR
                    } else {
                      return self.config.LINE_COLOR
                    }
                  },
                  lineWidth: function (context: any) {
                  // set line width for the line at 0 tick
                    if (context.tick.value === 0) {
                      return self.config.BORDER_WIDTH
                    } else {
                      return self.config.LINE_WIDTH
                    }
                  }
                },
                ticks: {
                // set font style for ticks in the graph - y axis
                  font: {
                    family: this.config.FONT_FAMILY,
                    size: this.config.FONT_SIZE
                  }
                }
              },
              x: {
                ticks: {
                // set font style for ticks in the graph - x axis
                  font: {
                    family: this.config.FONT_FAMILY,
                    size: this.config.FONT_SIZE
                  }
                }
              }
            }
          }
        }
        this.chartObject = new Chart(ctx, config)
      } catch (e) {
        this.hasError = true
      }
    }
  }

  formatLabels (labels: string[]) {
    const formattedLabels: any[] = []
    labels.map(label => {
      formattedLabels.push(this.removeTags(label))
    })
    return formattedLabels
  }

  unmounted () {
    if (this.chartObject) {
      this.chartObject.destroy()
    }
  }

  getBGcolor (bg_color: string, dataToBeDisplayed: any[]) {
    let i = 0
    const colors = []
    while (i < dataToBeDisplayed.length) {
      if (dataToBeDisplayed[i] >= 0) {
        colors.push(bg_color)
      } else {
        colors.push(this.config.NEGATIVE_BAR_COLOR)
      }
      i++
    }
    return colors
  }

  getFieldByKey (source: any, accessKey: string, sanitize = true) {
    const result = jsonPathService.getValueByField(source, accessKey)
    if (sanitize) {
      return this.$sanitize(result)
    } else {
      return result
    }
  }

  getColumnCount () {
    return this.container.columns.length + 1
  }

  removeTags (str: any) {
    if (!(str instanceof String) && !str) {
      return ''
    }
    return str.replace(/(<([^<]+)>)/ig, '')
  }
}
