import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../components/Home.vue'
import Result from '../components/Result.vue'
import Details from '../components/Details/Details.vue'
import PageNotFound from '../components/Error/PageNotFound.vue'
import Preview from '../components/Preview/Preview.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/details/:form/:id',
    name: 'Details',
    component: Details
  },
  {
    path: '/page-not-found',
    name: '404',
    component: PageNotFound
  },
  {
    path: '/data-not-found',
    name: '400',
    component: PageNotFound
  },
  {
    path: '/preview/:language/:view/:id',
    name: 'preview',
    component: Preview
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
