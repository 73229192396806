{
    "search_form": {
        "welcome": "Välkommen. Vad vill du söka på?",
        "person": "Person",
        "company": "Företag",
        "name": "Namn",
        "address": "Adress",
        "last_name": "Efternamn",
        "street": "Gatuadress",
        "zip_code": "Postnummer",
        "ssn": "Personnummer",
        "municipality": "Postort",
        "phonetic": "Fonetisk sökning",
        "phone": "Telefonnummer",
        "search": "SÖK",
        "org_num": "Organisationsnummer",
        "company_involvement": "Har företagsengagemang",
        "lakhs_corp_commitment": "Saknar företagsengagemang",
        "married": "Gift",
        "unmarried": "Ogift",
        "male": "Man",
        "female": "Kvinna",
        "minimum_age": "Min ålder",
        "maximum_age": "Max ålder",
        "city": "Postort",
        "more_details": "Fler alternativ",
        "birth_year": "Födelseår"
    },
    "alert": {
        "no_result": "Inga resultat.",
        "not_found_header": "Sidan hittades inte.",
        "not_found_detail": "Vi beklagar, sidan du begärde kunde inte hittas.",
        "logoutMessage": "Du har loggats ut från Acta Publica. Logga in igen för att fortsätta",
        "auth_required": "Inloggning krävs.",
        "no_record_found": "Förekomst saknas.",
        "no_access": "Du har inte tillgång till denna sektion.",
        "access_required": "APR åtkomst krävs.",
        "server_error_title": "Internt serverfel.",
        "server_error_details": "Servern stötte på ett internt fel eller en felkonfiguration och kunde inte slutföra din begäran.",
        "no_data": "Det finns inga tillgängliga data att visa.",
        "bad_gateway": "Vi fick ett ogiltigt svar från uppströmsservern. Försök igen",
        "gateway_timeout": "Servern tar för lång tid att svara. Var god försök igen",
        "settingsChanged": "Vi har nyligen gjort några ändringar i din åtkomst till tjänsten. Du har blivit utloggad. Vi ber dig att logga in igen så att dessa ändringar träder i kraft omedelbart.",
        "network_unavailable": "Du är offline, kontrollera din nätverksanslutning"
    },
    "result_page": {
        "title": "Sökresultat",
        "footer": "Visar { firstRowIndex } till { lastRowIndex } av { totalResultCount } träffar",
        "show_page_count": "VISA",
        "count": "TRÄFFAR"
    },
    "table": {
        "ssn": "Personnummer",
        "first_name": "Förnamn",
        "last_name": "Efternamn",
        "address": "Adress",
        "city": "Postort",
        "org_id": "Organisationsnummer",
        "company_name": "Företagsnamn",
        "company_code": "Företagsform",
        "street": "Gata"
    },
    "details_page": {
        "go_back": "Tillbaka till sökresultat",
        "print": "Skriv ut PDF rapport",
        "download": "Ladda ner",
        "back_to_home": "Tillbaka till hemmet"
    },
    "form_error": {
        "no_special_char": "Acceptera endast bindestreck (-) och understrykning (_) som specialtecken i '{field}'.",
        "birth_year": "Födelseåret bör ligga mellan {min} och {max}.",
        "name_or_address": "Antingen namn eller adress krävs.",
        "one_char_count": "Vänligen ange några sökord.",
        "max_char_count": "Högst 200 tecken är tillåtna."
    },
    "button": {
        "login": "Logga in",
        "advanced_search": "Avancerad sökning",
        "hide": "Dölj",
        "request_access": "Begär åtkomst",
        "annual_report": "Årsredovisning",
        "show_more": "Visa mer",
        "show_less": "Visa mindre"
    },
    "pagination": {
        "prev_btn": "« Föregående",
        "next_btn": "Nästa »"
    },
    "page_not_found": {
        "title": "Hoppsan! Sidan finns inte här tyvärr.",
        "back_to_home": "Gå till hemsidan",
        "contact_us": "Kontakta oss"
    },
    "data_not_found": {
        "title": "Hoppsan! De begärda uppgifterna hittades inte."
    },
    "company_types": {
        "select": "Välj bolagsform",
        "limited": "Aktiebolag",
        "sole": "Enskild firma",
        "trading": "Handelsbolag",
        "others": "Övriga"
    },
    "select_options": {
        "month": "Födelsemånad",
        "marital_status": "Civilstånd",
        "gender": "Kön",
        "company_involvement": "Företagsengagemang",
        "birth_day": "Födelsedag",
        "municipality": "Kommun",
        "county": "Region"
    },
    "months": {
        "01": "Januari",
        "02": "Februari",
        "03": "Mars",
        "04": "April",
        "05": "Maj",
        "06": "Juni",
        "07": "Juli",
        "08": "Augusti",
        "09": "September",
        "10": "Oktober",
        "11": "November",
        "12": "December"
    },
    "label": {
        "years": "År",
        "age_group": "Åldersgrupp",
        "person_result": "Personer",
        "company_result": "Företag",
        "available_report": "Tillgängliga rapporter",
        "loading": "Laddar..",
        "sort_by": "Filtrera efter",
        "age_from": "Ålder från",
        "to": "till",
        "reset": "Återställa"
    }
}