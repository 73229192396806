import { Vue, prop } from 'vue-class-component'
import apiService from '@/services/api-service'
import { ElMessage } from 'element-plus'
import { IPaginationParam } from '@/models/common'
import { Config } from '@/config'
import utilityService from '@/services/utility-service'
import {
  getAdvancedSearchFormValues,
  getSearchResultsParams,
  processPaginationSubscription,
  processTabSubscription,
  resetErrorData
} from '@/models/search'

// Define props in a class
class Props {
  page = prop({
    // Same as Vue core's prop option
    type: String,
    required: false,
    default: 'result'
  });
}
export default class PersonAdvanceSearch extends Vue.with(Props) {
  public searchResult: any[] = [];
  public searchingCompleted = false;
  showMoreOptions = false;
  age = Config.AGE_RANGE;
  ageRangeValue: number[] = [this.age.minimum_age, this.age.maximum_age];

  advancedPersonSearchForm: any = {};

  isFormValid = true;
  formError: any = {};
  paginationSubscription: any;
  apiService = apiService;
  fieldValidationSkipList = [
    'phonetic',
    'ssn',
    'company_involvement',
    'is_married',
    'minimum_age',
    'maximum_age',
    'birth_day',
    'county'
  ];

  countyList = [];
  municipalityList = [];
  birthYearLimit = { max: new Date().getFullYear(), min: 1800 };
  transitionClass: string = 'show';
  subscription: any;
  tabSubscription: any;
  filterSubscription: any;
  isPersonSearchRequestSent: boolean = false
  defaultPaginationParams: IPaginationParam = {
    page: 1,
    page_size: this.apiService.result.person.perPageCount,
    form: Config.CONSTANTS.PERSON
  }

  mounted () {
    this.resetFilterInput()
    this.resetErrors()
    const query = this.$route.query
    if (query.with_more_options) {
      this.showMoreOptions = true
    }

    this.advancedPersonSearchForm = getAdvancedSearchFormValues(query, this.advancedPersonSearchForm, Config.CONSTANTS.PERSON)

    this.onAgeChange()
    this.formatYearField()
    const commonData = sessionStorage.getItem('common-data')
    if (commonData) {
      const allData = JSON.parse(commonData)
      this.countyList = allData.countyList
      this.municipalityList = allData.municipalityList
    } else {
      this.apiService.getCommonData().then((data: any) => {
        this.countyList = data.county_data.counties
        this.municipalityList = data.municipality_data.municipalities
        sessionStorage.setItem(
          'common-data',
          JSON.stringify({
            countyList: this.countyList,
            municipalityList: this.municipalityList
          })
        )
      })
    }

    if (this.isCompactForm()) {
      this.paginationSubscription = processPaginationSubscription(this, Config.CONSTANTS.PERSON)
      this.tabSubscription = processTabSubscription(this, Config.CONSTANTS.PERSON)

      this.subscription = apiService
        .getSubscribableResultObject()
        .subscribe(data => {
          if (data.isSingleSearch) {
            this.advancedPersonSearchForm.name = this.$route.query.person_name
            this.advancedPersonSearchForm.address = this.$route.query.address
            if (data.searchType === Config.CONSTANTS.COMPANY && !data.isSilentRequest) {
              this.onAdvancedSearch(this.defaultPaginationParams, true)
            }
          }
        })
      this.filterSubscription = apiService.onQuickFilterChangedSubject.subscribe(
        data => {
          if (data) {
            if (data.key) {
              this.advancedPersonSearchForm[data.key] = data.value
              if (data.key === 'minimum_age' || data.key === 'maximum_age') {
                this.onAgeChange()
              }
            } else if (data.reset) {
              this.resetFilterInput()
              this.resetForms(false, data.clearResult)
            }
            if (data.form !== Config.CONSTANTS.COMPANY && data.doSearch) {
              this.onAdvancedSearch()
            }
          }
        }
      )
    }
  }

  resetFilterInput () {
    // Note: Changes in this variable (advancedPersonSearchForm) should update in apiService method checkForActiveFilters
    this.advancedPersonSearchForm = {
      name: '',
      address: '',
      zip_code: '',
      ssn: '',
      last_name: '',
      phonetic: true,
      phone: '',
      birth_year: '',
      county_code: '',
      municipality_code: '',
      birth_month: '',
      birth_day: '',
      company_involvement: '',
      is_married: '',
      gender: '',
      minimum_age: this.age.minimum_age,
      maximum_age: this.age.maximum_age
    }
    this.onAgeChange()
  }

  formatYearField () {
    const age = Number(this.advancedPersonSearchForm.birth_year)
    this.advancedPersonSearchForm.birth_year = age > 0 ? age : ''
  }

  public hideNoResultMsg () {
    this.hideToast()
    this.searchingCompleted = false
  }

  public isCompactForm () {
    return this.page === 'result'
  }

  public canShowNoResult () {
    return (
      this.searchingCompleted &&
      this.searchResult.length === 0 &&
      this.page === 'home'
    )
  }

  public validateForm (formObj: any) {
    this.hideToast()
    // Reset error flags
    this.resetErrors()
    for (const [key] of Object.entries(formObj)) {
      // Skip validation for listed fields in the object 'fieldValidationSkipList'
      if (this.fieldValidationSkipList.indexOf(key) === -1) {
        if (key === 'birth_year') {
          this.validateBirthYearField(formObj, key)
        } else {
          this.validateFieldsLength(formObj, key, false)
        }
        this.validateFieldForSpecialChar(formObj, key)
      }
    }
    return this.isFormValid
  }

  validateBirthYearField (formObj: any, field: string) {
    const value = formObj[field]
    if (
      value &&
      (value > this.birthYearLimit.max || value < this.birthYearLimit.min)
    ) {
      this.onFieldValidationFailed(
        field,
        this.$t('default.form_error.birth_year', {
          min: this.birthYearLimit.min,
          max: this.birthYearLimit.max
        })
      )
    }
  }

  validateFieldsLength (formObj: any, field: string, isRequired: boolean) {
    if (isRequired || (!isRequired && formObj[field] && formObj[field].length > 0)) {
      const valueLength = formObj[field].length
      if (valueLength > 200) {
        this.onFieldValidationFailed(
          field,
          this.$t('default.form_error.max_char_count', {
            field: this.getPlaceholderKeyByField(field)
          })
        )
      }
    }
  }

  validateFieldForSpecialChar (formObj: any, field: string) {
    // Special characters validation
    /* eslint-disable */
    const pattern = /['!@#$%^&*()+\=\[\]{};:"\\|<>\/?~]/;
    if (formObj[field]?.match(pattern)) {
      this.onFieldValidationFailed(
        field,
        this.$t("default.form_error.no_special_char", {
          field: this.getPlaceholderKeyByField(field)
        })
      );
    }
  }

  getPlaceholderKeyByField(field: string) {
    return this.$t("default.search_form." + field);
  }

  onFieldValidationFailed(field: string, message: string) {
    this.formError[field] = true;
    this.isFormValid = false;
    utilityService.showToast(message, "error", "form_error");
  }

  hideToast() {
    utilityService.hideToast()
  }

  resetErrors() {
    this.isFormValid = true;
    this.formError = resetErrorData
    this.hideToast();
  }

  resetForms(emitResult = true, clearResult = true) {
    if (clearResult) {
      this.apiService.clearResult(Config.CONSTANTS.PERSON, emitResult);
    }
    this.resetErrors();
  }

  unmounted() {
    // unsubscribe to ensure no memory leaks
    this.paginationSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.tabSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }
  
  canShowFormError(control: string) {
    return this.formError[control];
  }

  public onAdvancedSearch(
    paginationParam: IPaginationParam = this.defaultPaginationParams,
    isSilentRequest? : boolean
  ) {
    this.queryServer(
      paginationParam,
      this.getFormattedPersonFilter(),
      this.apiService.currentSearchForm,
      isSilentRequest
    );
  }

  getFormattedPersonFilter() {
    let data: any = {
      name: this.advancedPersonSearchForm.name,
      address: this.advancedPersonSearchForm.address,
      zip_code: this.advancedPersonSearchForm.zip_code,
      ssn: this.advancedPersonSearchForm.ssn,
      phonetic: this.advancedPersonSearchForm.phonetic,
      phone: this.advancedPersonSearchForm.phone,
      last_name: this.advancedPersonSearchForm.last_name
    };
    data.county_code = this.advancedPersonSearchForm.county_code;
    data.municipality_code = this.advancedPersonSearchForm.municipality_code;
    data.birth_year = this.advancedPersonSearchForm.birth_year;
    data.birth_month = this.advancedPersonSearchForm.birth_month;
    data.birth_day = this.advancedPersonSearchForm.birth_day;
    data.company_involvement = this.advancedPersonSearchForm.company_involvement;
    data.gender = this.advancedPersonSearchForm.gender;
    data.minimum_age = this.ageRangeValue[0];
    data.maximum_age = this.ageRangeValue[1];
    data.is_married = this.advancedPersonSearchForm.is_married;

    return data;
  }

  public queryServer(
    paginationParam: IPaginationParam = {
      page: 1,
      page_size: this.apiService.result.person.perPageCount,
      form: Config.CONSTANTS.PERSON
    },
    formData: any,
    url: string,
    isSilentRequest?: boolean
  ) {
    this.hideNoResultMsg();
    if (this.validateForm(formData) && !this.isPersonSearchRequestSent) {
      this.isPersonSearchRequestSent = true
      // If searchingCompleted is true and searchResult.length is 0 then show no data found msg
      formData.type = Config.CONSTANTS.PERSON;
      this.apiService
        .getSearchResult({ ...formData, ...paginationParam }, `/${url}`,  isSilentRequest)
        .then((data: any) => {
          this.isPersonSearchRequestSent = false
          this.searchResult = data.person.result;
          this.searchingCompleted = true;
          const params = getSearchResultsParams(this.$route.query, formData, paginationParam, Config.CONSTANTS.PERSON)
          apiService.lastExecutedQuery = params
          if (!isSilentRequest) { 
            this.$router.push({
              name: "Result",
              query: params
            })
          }
          this.$nextTick(()=> {
            apiService.emitNewResult(Config.CONSTANTS.PERSON, false)
          })
        }).catch(()=> this.isPersonSearchRequestSent = false)
    }
  }

  resetSearchField(field: string) {
    if (field === 'name' || field === 'address') {
      apiService.personFilterObj[field] = ""
    }
    this.advancedPersonSearchForm[field] = "";
    this.formError[field] = false;
  }

  canShowClearButton(field: string) {
    let value: any = "";
    value = this.advancedPersonSearchForm[field];
    if (
      field === "maximum_age" ||
      field === "minimum_age" ||
      field === "birth_year"
    ) {
      if (value) {
        value = value.toString();
      } else {
        value = "";
      }
    }
    return value?.length;
  }

  getMonths() {
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12"
    ];
  }

  getDates() {
    return [...Array(31).keys()].map(i => i + 1);
  }

  onAgeChange(event?: any) {
    this.advancedPersonSearchForm.minimum_age =
      parseInt(this.advancedPersonSearchForm.minimum_age) || 0;
    this.advancedPersonSearchForm.maximum_age =
      parseInt(this.advancedPersonSearchForm.maximum_age) ||
      this.age.maximum_age;
    this.ageRangeValue = [
      this.advancedPersonSearchForm.minimum_age,
      this.advancedPersonSearchForm.maximum_age
    ];
  }

  canShowMoreFilter() {
    return this.showMoreOptions;
  }

  onYearKeyPress(event: any) {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }
}
